.filter-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    height: 100%;
}

.fixed-tags {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.scrollable-tags-container {
    flex: 1;
    min-width: 0;
    margin: 0 16px;
}

.scrollable-tags {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    
    &::-webkit-scrollbar {
        height: 6px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
    
    &::-webkit-scrollbar-button {
        display: none;
    }
}

.pagination-container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.ant-pagination.css-dev-only-do-not-override-5wsri9.ant-pagination-simple {
    margin-top: 0 !important;
}